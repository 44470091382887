<template>
    <div class="mall-banner">
        <div class="container mall-banner-content">
            <!-- position -->
          <!--<div class="mall-banner-position">
            {{pagePosition}}
            </div>-->
          <!-- search -->
            <div class="mall-banner-search">
                <slot name="search"></slot>
            </div>
            <!-- router -->
            <!-- <router-link
                :to="routerPath"
                class="mall-banner-router">
                {{routerName}}
                <i class="el-icon-d-arrow-right"></i>
            </router-link> -->
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            pagePosition: String,
            routerName: String,
            routerPath: String
        },
    }
</script>

<style scoped>
.mall-banner{
    position: relative;
    height: 200px;
    background-color: rgb(34, 75, 181);
}
.mall-banner-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* position */
.mall-banner-position{
    padding: 8px 20px;
    background-color: var(--color);
    border-radius: 30px;
    letter-spacing: 4px;
    font-size: 22px;
    font-weight: lighter;
    color: #fff;
}
/* search */
.mall-banner-search{
    width: 600px;
}
/* router */
.mall-banner-router{
    letter-spacing: 2px;
    font-weight: lighter;
    color: #fff;
}
</style>
